<script setup>
import { useOnline } from '@vueuse/core'
import { useDisplay } from 'vuetify'

// mobile breakpoint
const { xs } = useDisplay()

const online = useOnline()
</script>

<template>
    <v-app id="stnwl">
        <!-- Nav bar at the top contains logo, links and cart icon -->
        <the-nav-bar />

        <!-- Text based ad ticker -->
        <!--ad-ticker /-->

        <!-- Nav bar for small devices shows up when hamburger menu is clicked -->
        <the-nav-drawer />

        <!-- Cart side bar shows up when cart icon is clicked -->
        <the-cart-drawer />

        <!-- Main section -->
        <v-main v-if="online" id="stnwlmain">
            <v-container id="stnwlcontainer" fluid class="align-start justify-center">
                <!-- Placeholder for pages -->
                <slot />

                <the-footer class="rounded-t" />

                <the-bottom />
            </v-container>

            <!-- Image at the bottom -->
            <!-- <v-container v-if="!xs" fluid class="mt-16 pa-0">
                <v-sheet>
                    <v-img src="/wbst-banner-01.webp" max-height="350" alt="banner image at footer"></v-img>
                </v-sheet>
            </v-container> -->

            <!-- Cookie consent banner -->
            <client-only>
                <the-cookie-consent />
            </client-only>
        </v-main>
        <v-main v-else id="stnwlerror">
            <app-error
                :title="`Offline`"
                :icon="`mdi-wifi-alert`"
                :message="`You are offline.`"
                :showLinks="false"
                :details="`Please check your internet connection`"
            />
        </v-main>
    </v-app>
</template>

<style scoped></style>
